import React, { memo } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { HelpLink } from "../helpLink/HelpLink";
import { Button } from "../button/Button";
import { NotMobile, Mobile } from "../../hocs/Responsive";
import { isMultipleUserLevel } from "../../store/account";
import { useIntlTranslate } from "../../hooks";
import { Icon } from "../icon/Icon";
import { getDefaultProfile } from "../../store/profiles/profiles.selectors";

import "./SpsonInfo.scss";

const ReadMoreLink = () => (
  <div className="spson-info__more-info">
    <HelpLink section={"sps-remote-client"}>
      <Icon icon="far fa-question-circle" />
      <FormattedMessage id="get_help" />
    </HelpLink>
  </div>
);

const SpsonInfoPanel = () => {
  const translate = useIntlTranslate();
  const isMultiple = useSelector(isMultipleUserLevel);
  const defaultProfileId = useSelector(state => getDefaultProfile(state).id);

  return (
    <div className="spson-info">
      <div className="spson-info__content">
        <div className="spson-info__title">
          <Icon icon="fas fa-shield-check icon--spson" />
          <FormattedMessage id="spson" />
        </div>
        <Mobile>
          <ReadMoreLink />
        </Mobile>
        <div className="spson-info__description">
          <FormattedMessage id="spson_description" />
        </div>
      </div>

      <div className="spson-info__actions">
        <NotMobile>
          <ReadMoreLink />
        </NotMobile>
        <div className="spson-info__button">
          <Button
            as={Link}
            to={`/settings/${
              isMultiple ? `devices/${defaultProfileId}/roaming` : "sps-remote"
            }`}
            size={"large"}
            aria-label={translate("aria_get_sps_on_started", {
              page: translate(isMultiple ? "aria_devices" : "aria_sps_remote")
            })}
          >
            <FormattedMessage id="get_started" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export const SpsonInfo = memo(SpsonInfoPanel);
