import React from "react";
import _ from "lodash";
import { add } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";

import { sleep } from "../../helpers/promises.helper";
import { ModalService } from "../modal";
import { DeeplinkSuccessDialog, DeeplinkErrorDialog } from "./DeeplinkDialog";
import { is24HourFormat, getTimeZone } from "../../store/account";
import { isSessionExpired } from "../../utils/isSessionExpired";

export const generateDeeplink = ({ delayMs } = {}) => async (
  dispatch,
  getState,
  { api }
) => {
  if (delayMs) {
    await sleep(delayMs);
  }

  const id = getState().subscriberInfo.id;

  try {
    const {
      data: { link, "deeplink-ttl": expirationDuration }
    } = await api.ssm.account.generateDeeplink(id);

    const state = getState();

    const is24 = is24HourFormat(state);
    const tz = getTimeZone(state);

    ModalService.show(modal => {
      const now = utcToZonedTime(Date.now(), tz);
      const expires = format(
        add(now, { seconds: expirationDuration }),
        is24 ? "MM/dd/yyyy HH:mm" : "MM/dd/yyyy hh:mm aaa"
      );

      return {
        dialog: (
          <DeeplinkSuccessDialog modal={modal} link={link} expires={expires} />
        )
      };
    }).then(_.noop, _.noop);
  } catch (e) {
    if (isSessionExpired(e)) {
      dispatch({ payload: e, type: GENERATE_DEEPLINK_FAILURE });
      return;
    }

    function onTryAgain() {
      dispatch(generateDeeplink({ delayMs: 1000 }));
    }

    ModalService.show(modal => ({
      dialog: <DeeplinkErrorDialog modal={modal} />
    })).then(() => onTryAgain(), _.noop);
  }
};

const GENERATE_DEEPLINK_FAILURE = "[SPSON] GENERATE_DEEPLINK_FAILURE";
