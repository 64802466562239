import {
  AccountData,
  AttributesServerData,
  BaseAppConfig,
  ServiceProfiles
} from "@sportal/api";
import { BaseApiService } from "../baseApiService";
import {
  ATTRIBUTES,
  FREQUENCIES
} from "../../store/attributes/attributes.constants";

export class AccountService extends BaseApiService {
  accountUrl: string;
  controlUrl: string;

  constructor(config: BaseAppConfig) {
    super(config);
    this.accountUrl = `${config.backendUrl}/ssm/account`;
    this.controlUrl = `${config.backendUrl}/ssm/control`;
  }

  getAccount(subscriberId: string): Promise<{ data: AccountData }> {
    return this._http.get(`${this.accountUrl}/${subscriberId}`);
  }

  createAccount({
    id,
    timezone
  }: {
    id: string;
    timezone: string;
  }): Promise<void> {
    return this._http.post(`${this.controlUrl}`, {
      id,
      "time-zone": timezone
    });
  }

  activateService({
    id,
    service,
    profile
  }: {
    id: string;
    service: string;
    profile: ServiceProfiles;
  }): Promise<void> {
    return this._http.post(`${this.controlUrl}/${id}/service`, {
      service,
      "service-profile": profile
    });
  }

  getAttributes(subscriberId: string): Promise<AttributesServerData> {
    return this._http.get(`${this.accountUrl}/${subscriberId}/attribute`);
  }

  updateAttribute(
    subscriberId: string,
    attribute: { name: ATTRIBUTES; value: string }
  ): Promise<void> {
    let { name, value } = attribute;
    if (name === ATTRIBUTES.EMAIL_FREQUENCY && value === FREQUENCIES.NEVER) {
      value = null;
    }

    return this._http.put(
      `${this.accountUrl}/${subscriberId}/attribute/${name}`,
      { name, value }
    );
  }

  updateSubscriber(
    subscriberId: string,
    attribute: { [key: string]: string | boolean }
  ): Promise<void> {
    return this._http.patch(`${this.accountUrl}/${subscriberId}`, attribute);
  }

  generateDeeplink(
    subscriberId: string
  ): Promise<{ data: { link: string; "deeplink-ttl": number } }> {
    return this._http.post(
      `${this.accountUrl}/${subscriberId}/spson/deeplink`,
      {}
    );
  }

  getSpsonLimit(subscriberId: string): Promise<number> {
    return this._http
      .get(`${this.accountUrl}/${subscriberId}/spson`)
      .then(({ data: { "allowed-devices": roamingLimit } }) => roamingLimit);
  }
}
