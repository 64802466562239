import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { useMount } from "@sportal/cdk/hooks";
import { SpsonInfo } from "../../components/spsonInfo/SpsonInfo";
import { Desktop, TabletMobile } from "../../hocs/Responsive";
import { isSPSONAvailable } from "../../store/account";
import { anyNewRequests } from "../../store/devices/devices.selectors";
import { BarChartSection } from "./barChartSection/BarChartSection";
import { WidgetsSection } from "./widgets/WidgetsSection";
import { NewRequests } from "./NewRequests";
import { setupDashboard } from "./dashboard.actions";

import "./Dashboard.scss";

const getRowClasses = showAside =>
  classnames("dashboard-row", {
    "with-aside": showAside
  });

export const ChartTitle = ({ title }) => (
  <h4 className="chart-title">{title}</h4>
);

export const Dashboard = () => {
  const dispatch = useDispatch();

  const showSPSON = useSelector(isSPSONAvailable);
  const hasNewRequests = useSelector(anyNewRequests);

  useMount(() => {
    dispatch(setupDashboard());
  });

  return (
    <div className="home-page__dashboard">
      <h2 className={"home-page__title"}>
        <FormattedMessage id={"dashboard"} />
      </h2>
      <TabletMobile>
        {showSPSON && <SpsonInfo />}
        {hasNewRequests && <NewRequests />}
      </TabletMobile>
      <div className={getRowClasses(showSPSON || hasNewRequests)}>
        <WidgetsSection />
        <Desktop>
          {showSPSON && <SpsonInfo />}
          {!showSPSON && hasNewRequests && <NewRequests />}
        </Desktop>
      </div>
      <div className={getRowClasses(showSPSON && hasNewRequests)}>
        <BarChartSection />
        {showSPSON && hasNewRequests && (
          <Desktop>
            <NewRequests />
          </Desktop>
        )}
      </div>
    </div>
  );
};
