import React from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { useIntlTranslate } from "../../hooks";
import { CommonModalHeader, ModalDialog } from "../modal";
import { Copyable } from "../copyable/Copyable";
import ButtonLink from "../buttonLink/ButtonLink";
import { Button } from "../button/Button";
import { Icon } from "../icon/Icon";

import "./DeeplinkDialog.scss";

const DeeplinkGenerationStatus = ({ icon, messageId }) => (
  <div className="deeplink-modal__status">
    <Icon icon={classnames(icon, "deeplink-modal__icon")} />
    <FormattedMessage id={messageId} />
  </div>
);

const DeeplinkDialog = ({ className, onSubmit, onClose, children }) => (
  <ModalDialog
    className={classnames("deeplink-modal", className)}
    submitHandler={onSubmit}
    rejectHandler={onClose}
  >
    <CommonModalHeader>
      <FormattedMessage id="instalation_link" />
    </CommonModalHeader>
    {children}
  </ModalDialog>
);

export const DeeplinkErrorDialog = ({ modal }) => {
  const close = () => modal.reject();
  const submit = () => modal.resolve();

  return (
    <DeeplinkDialog
      className="deeplink-modal--error"
      onSubmit={submit}
      onClose={close}
    >
      <ModalDialog.Body>
        <DeeplinkGenerationStatus
          icon="fas fa-exclamation-triangle"
          messageId="deeplink_generation_status_error"
        />
        <div className="deeplink-modal__text">
          <FormattedMessage id="deeplink_generation_failure" />
        </div>
      </ModalDialog.Body>
      <ModalDialog.Footer>
        <Button size={"large"} onClick={submit}>
          <FormattedMessage id="deeplink_generation_try_again" />
        </Button>
      </ModalDialog.Footer>
    </DeeplinkDialog>
  );
};

export const DeeplinkSuccessDialog = ({ modal, link, expires }) => {
  const close = () => modal.reject();
  const submit = () => modal.resolve();

  const translate = useIntlTranslate();

  return (
    <DeeplinkDialog
      className="deeplink-modal--success"
      onSubmit={submit}
      onClose={close}
    >
      <ModalDialog.Body>
        <DeeplinkGenerationStatus
          icon="fas fa-check-circle"
          messageId="deeplink_generation_status_success"
        />
        <div className="deeplink-modal__text">
          <FormattedMessage id="deeplink_generation_instructions" />
        </div>
      </ModalDialog.Body>
      <ModalDialog.Footer>
        <div>
          <Copyable text={link}>
            <Button size={"large"}>
              <FormattedMessage id="copy_link" />
            </Button>
          </Copyable>
          <div className="deeplink-modal__copy-with-instructions">
            <Copyable
              text={translate("copy_link_with_instructions_content", {
                link,
                expires
              })}
            >
              <ButtonLink>
                <Icon icon="fas fa-copy icon-copy" />
                <FormattedMessage id="copy_link_with_instructions" />
              </ButtonLink>
            </Copyable>
            <div className="deeplink-modal__copy-with-instructions__text">
              <FormattedMessage
                id="copy_link_instructions"
                values={{
                  b: chunks => <b>{chunks}</b>,
                  expires
                }}
              />
            </div>
          </div>
        </div>
      </ModalDialog.Footer>
    </DeeplinkDialog>
  );
};
