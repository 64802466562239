import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classnames from "classnames";

import "./WizardPage.scss";

import { Button } from "../../components/button/Button";
import { WizardProfiles } from "../profiles/WizardProfiles";
import { withLayout } from "../../components/layout/Layout";
import Notificator from "../../components/notification/notification.actions";
import { GoBack } from "../../components/goBack/goBack";
import { activateServices } from "../../store/account";
import {
  setInitialProfiles,
  saveProfilesRequest,
  setSelectedProfile as setSelected
} from "../../store/profiles/profiles.actions";
import {
  getWizardSelectedProfileId,
  getVisibleProfiles
} from "../../store/profiles/profiles.selectors";

class WizardPageComponent extends Component {
  className = "wizard-page";

  componentDidMount() {
    this.props.setInitialProfiles();
  }

  render() {
    const {
      hasAvailableProfiles,
      limit,
      selectedProfile,
      setSelectedProfile,
      finishSetup,
      customProfilesAmount,
      isSingle
    } = this.props;

    const wizardClasses = classnames(this.className, {
      [`${this.className}--single`]: isSingle
    });

    return (
      <div className={wizardClasses}>
        <GoBack url={"#wizard/protection-choice"} />
        {// TODO: Consider setting profiles outside of this component
        //   since currently `componentDidMount` is called after children's
        //   so we always have at least one unneeded remount
        hasAvailableProfiles && (
          <WizardProfiles
            limit={limit}
            selected={selectedProfile}
            onSelect={setSelectedProfile}
            customProfilesAmount={customProfilesAmount}
          />
        )}
        <div className="wizard-page__btn-container">
          <Button
            className="wizard-page__action-btn"
            onClick={finishSetup}
            size={"large"}
          >
            <FormattedMessage id="complete_onboarding" />
          </Button>
        </div>
      </div>
    );
  }
}

WizardPageComponent.propTypes = {
  hasAvailableProfiles: PropTypes.bool.isRequired,
  selectedProfile: PropTypes.string,
  setInitialProfiles: PropTypes.func.isRequired,
  setSelectedProfile: PropTypes.func.isRequired,
  finishSetup: PropTypes.func.isRequired,
  customProfilesAmount: PropTypes.number.isRequired,
  isSingle: PropTypes.bool
};

export const WizardPage = _.flowRight(
  withRouter,
  withLayout({
    showHeaderUnderline: true,
    showNavigation: false,
    showUserInfo: false,
    showLogOutLink: true
  }),
  injectIntl,
  connect(
    state => ({
      hasAvailableProfiles: !_.isEmpty(getVisibleProfiles(state)),
      limit: state.profiles.limit,
      selectedProfile: getWizardSelectedProfileId(state),
      customProfilesAmount: state.profiles.changed.keys.length - 3
    }),
    (dispatch, { isSingle }) => ({
      setInitialProfiles: () => dispatch(setInitialProfiles({ isSingle })),
      finishSetup: () =>
        // TODO: consider putting kind of `isSingle` flag right to the store
        //  so that actions could access it directly without passing it through
        //  components.
        dispatch(activateServices({ single: isSingle })).then(() =>
          dispatch(saveProfilesRequest()).catch(({ customErrors }) => {
            const defaultMsg = "failed_to_create_profiles";
            const message = _.get(customErrors, [0, "message"], defaultMsg);

            dispatch(Notificator.error(<FormattedMessage id={message} />));
          })
        ),
      setSelectedProfile: id => dispatch(setSelected(id))
    })
  )
)(WizardPageComponent);
