import React from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { getServices, SERVICES } from "../../common/utils/permissions";
import Notificator from "../../components/notification/notification.actions";
import { DEFAULT_TIME_ZONE } from "../attributes/attributes.constants";
import {
  ACTIVATE_SERVICES_FAILURE,
  CREATE_ACCOUNT_FAILURE,
  GET_ACCOUNT_SUCCESS,
  UPDATE_WEB_FILTER_FAILURE,
  UPDATE_WEB_FILTER_SUCCESS
} from "./account.types";
import { AccountData, CreateAccountData } from "@sportal/api";
import { AccountActionTypes } from "./account.types";

export const getAccount = () => (dispatch, getState, { api }) => {
  const id = getState().subscriberInfo.id;
  const defaultTimezone =
    getState().subscriberInfo["default-timezone"] || DEFAULT_TIME_ZONE;

  if (!id) return null;

  return api.ssm.account
    .getAccount(id)
    .then(({ data }) => {
      //TODO: I'm not sure that it is the best way, but this just for excluding unused property from the store and mapping with default if it's needed
      const mappedObject = {
        ...data,
        timezone: data["time-zone"] || defaultTimezone
      };

      dispatch(getAccountSuccess(mappedObject));
    })
    .catch(error => {
      if (error.response && error.response.status !== 404) {
        dispatch(generalFailure(error, ACTIVATE_SERVICES_FAILURE));
        return Promise.reject();
      }

      return api.ssm.account
        .createAccount({ id, timezone: defaultTimezone })
        .then(() =>
          dispatch(getAccountSuccess({ id, timezone: defaultTimezone }))
        )
        .catch(error => {
          dispatch(generalFailure(error, CREATE_ACCOUNT_FAILURE));
          return Promise.reject();
        });
    });
};

export const activateServices = ({ single }) => (
  dispatch,
  getState,
  { api }
) => {
  const {
      subscriberInfo: { id, mode },
      account
    } = getState(),
    services = getServices({
      access: mode,
      single: single
    });

  return services
    .reduce(
      (chain, service) =>
        !(account[service.service] && account[service.service].activated)
          ? chain.then(() =>
              api.ssm.account.activateService({ id, ...service })
            )
          : Promise.resolve(),
      Promise.resolve()
    )
    .then(() => dispatch(activateServiceSuccess()))
    .catch(error => {
      dispatch(
        Notificator.error(<FormattedMessage id={"couldnt_activate_services"} />)
      );
      dispatch(generalFailure(error, ACTIVATE_SERVICES_FAILURE));
      return Promise.reject();
    });
};

export const activateServiceSuccess = () => (dispatch, getState, { api }) => {
  return api.ssm.account
    .getAccount(getState().subscriberInfo.id)
    .then(response => dispatch(getAccountSuccess(response.data)));
};

export const updateWebFilter = (webFilter: boolean) => async (
  dispatch,
  getState,
  { api }
) => {
  const { subscriberInfo } = getState();

  try {
    await api.ssm.account.updateSubscriber(subscriberInfo.id, {
      [SERVICES.SB]: webFilter
    });
    dispatch(updateWebFilterSuccess(webFilter));

    if (webFilter) {
      dispatch(Notificator.remove("webFilterOff"));
      return;
    }

    dispatch(Notificator.webFilterOff());
  } catch (e) {
    dispatch(
      Notificator.error(<FormattedMessage id={"couldnt_save_changes"} />)
    );
    dispatch(generalFailure(e, UPDATE_WEB_FILTER_FAILURE));
  }
};

export const getAccountSuccess = (
  data: AccountData | CreateAccountData
): AccountActionTypes => ({
  type: GET_ACCOUNT_SUCCESS,
  payload: _.omit(data, "time-zone")
});
export const updateWebFilterSuccess = (
  status: boolean
): AccountActionTypes => ({
  type: UPDATE_WEB_FILTER_SUCCESS,
  payload: status
});
export const generalFailure = (error, type): AccountActionTypes => ({
  type,
  payload: error
});
