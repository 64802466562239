import _ from "lodash";
import { Service, ServiceProfiles } from "@sportal/api";
import { getAccount, getAccountSettings } from "../root.selectors";
import { getSavedProfilesList } from "../profiles/profiles.selectors";

const getService = serviceName => state => getAccount(state)[serviceName];
export const getSBAccountService = state =>
  getService(Service.SecureBusiness)(state);

const isSPSONEnabled = service => service && service["spson-enabled"];

export const isSPSONAvailable = _.flow(
  getSBAccountService,
  isSPSONEnabled,
  Boolean
);

export const isMultipleUserLevel = state => {
  const service = getSBAccountService(state);

  return service && service.type === ServiceProfiles.SBMultiple;
};

export const isSBProvisionedWithoutProfiles = state => {
  const service = getSBAccountService(state);
  const profiles = getSavedProfilesList(state);

  return service && service.activated && profiles.length === 0;
};

export const is24HourFormat = _.flow(
  getAccountSettings,
  accountSettings => accountSettings.is24hourFormat
);
export const getTimeZone = _.flow(
  getAccountSettings,
  accountSettings => accountSettings.timezone
);
